import React from 'react';
import './main.css'
import { DataGrid, GridToolbar  } from '@mui/x-data-grid';
import data from './data/do_not_ban_list.json'
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DownloadIcon from '@mui/icons-material/Download';

//Datagrid
const columns = [
    { field: 'id', headerName: 'ID', width: 95 },
    {
        field: 'time',
        headerName: 'Time',
        width: 150,
        editable: false,
    },
    {
      field: 'action',
      headerName: 'Type',
      width: 107,
      editable: false,
    },
    {
      field: 'action_by',
      headerName: 'Actioned By',
      width: 153,
      editable: false,
    },
    {
        field: 'username',
        headerName: 'Twitch Name',
        width: 159,
        editable: false,
    },
    {
        field: 'reason',
        headerName: 'Do Not Ban Reason',
        width: 600,
        editable: false,
    },
  ];
  
  const rows = data;
  
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  

const Main = () => {

    //Snackbar Notification

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
      setOpen(true);
    };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    return (
       <div className="table-root">
            <p className="table-title">Twitch Do Not Ban List</p>
            <p className="table-description">The ban list - Twitch emails will be censored.</p>
            <Button className="table-downloadButton" href="./files/do_not_ban_list.json" download="DoNotBanList" target='_blank' variant="contained" color="success" startIcon={<DownloadIcon />} onClick={handleClick} style={{
                borderRadius: 35,
                backgroundColor: "#6441a5",
                padding: "10px 36px",
                color: "#ffffff"
            }}>
                Download Data
            </Button>
            <Snackbar open={open} autoHideDuration={2250} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Downloading
                </Alert>
            </Snackbar>
            <div style={{ height: 700, width: '95%', paddingLeft: "2.5%", paddingRight: "2.5%", fontSize: 25}}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[5]}
                    components={{
                      Toolbar: GridToolbar,
                    }}
                />
            </div>
       </div>
    );
}

export default Main; 