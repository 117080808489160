import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
 
import Earnings from './components/earnings';
import Library from './components/library';
import DoNotBanList from './components/doNotBanList'
import maliciousURL from './components/maliciousURL'
import gameCategories from './components/twitchGameCategories'

import Header from './components/Header'
import Error from './components/error';


class App extends Component {
  render() {
    return (      
       <BrowserRouter>
        <div>
        <Header />
            <Switch>
              <Route path="/" component={Earnings} exact/>
              <Route path="/libary" component={Library} exact/>
              <Route path="/do-not-ban-list" component={DoNotBanList} exact/>
              <Route path="/malicious-urls" component={maliciousURL} exact/>
              <Route path="/game-categories" component={gameCategories} exact/>
              <Route component={Error}/>
           </Switch>
        </div> 
      </BrowserRouter>
    );
  }
}
 
export default App;