//import useState hook to create menu collapse state
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import logo from './img/Twitch-Logo.png'

//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";

//import icons from react icons
import TableRowsIcon from '@mui/icons-material/TableRows';
import HomeIcon from '@mui/icons-material/Home';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import IconButton from '@mui/material/IconButton';

//import sidebar css from react-pro-sidebar module and our custom css 
import "react-pro-sidebar/dist/css/styles.css";
import "./Header.css";


const Header = () => {
  
  //create initial menuCollapse state using useState hook
  const [menuCollapse, setMenuCollapse] = useState(true)

  //create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  return (
    <>
      <div id="header">
        <IconButton className="icon" aria-label="delete" onClick={menuIconClick}>
            <MenuOpenIcon />
        </IconButton>
          {/* collapsed props to change menu size using menucollapse state */}
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader>
          <div className="logotext">
              {/* small and big change using menucollapse state */}
              <img className="twitchMenuLogo" src={logo} alt="logo"/>
            </div>
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="square">
              <MenuItem icon={<HomeIcon style={{fill: "white"}}/>}><Link to="/" />Home</MenuItem>
              <SubMenu className="subMenu" title="Table Libary" icon={<TableRowsIcon style={{fill: "white"}}/>}>
                <MenuItem className="subMenu"><Link to="/do-not-ban-list" />Do Not Ban List</MenuItem>
                <MenuItem className="subMenu"><Link to="/malicious-urls" />Malicious URLs</MenuItem>
                <MenuItem className="subMenu"><Link to="/game-categories" />Game Categories</MenuItem>
            </SubMenu>
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>
    </>
  );
};

export default Header;