import React from 'react';
import './main.css'

const Library = () => {
    return (
        <div>
            <p className="libary-title">Twitch Data Library</p>
            <p className="libary-description">This data was retrieved from the Twitch Data leak that happened in October 2021.</p>
        </div>
    );
}

export default Library; 