import React from 'react';
import './main.css'
import notLikeThis from './img/notLikeThis.gif'
import Button from '@mui/material/Button';



const Error = () => {
    return (
      <div className="error-root">
            <img className="notLikeThis" src={notLikeThis} alt="modCheck"/>
            <p className="errorText">4 OMEGALUL 4</p>
            <Button color="success" href="/" style={{
                borderRadius: 35,
                backgroundColor: "#6441a5",
                padding: "10px 36px",
                color: "#ffffff"
            }}>
                Take Me Home
            </Button>
        </div>
    );
}

export default Error; 